import { GlobalConfig } from '@simphera/shared/appconfig'
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { setUserInfo } from '../state'

const userManager = new UserManager({
  // The OpenID Connect base URL
  authority: GlobalConfig.appConfig.OIDC_ISSUER_URL,

  // Core OAuth settings for our app
  client_id: GlobalConfig.appConfig.OIDC_CLIENT_ID,
  redirect_uri: GlobalConfig.appConfig.APP_URL + 'authentication',
  scope: GlobalConfig.appConfig.OIDC_SCOPE,

  // https://devforum.okta.com/t/why-no-userinfo-endpoint-in-well-known-auth-server-metadata/6816
  metadataUrl: `${GlobalConfig.appConfig.OIDC_ISSUER_URL}/.well-known/openid-configuration`,

  // Indicate the logout return path and listen for logout events from other browser tabs
  post_logout_redirect_uri:
    GlobalConfig.appConfig.OIDC_POST_LOGOUT_REDIRECT_URI,

  // Use the Authorization Code Flow (PKCE)
  response_type: 'code',

  silent_redirect_uri: GlobalConfig.appConfig.APP_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),

  accessTokenExpiringNotificationTimeInSeconds: 30, // 30s before token expiry, try to get new one
})

export class AuthManger {
  private isAlreadySigningIn: boolean = false

  /** THIS MUST ONLY BE CALLED ONCE */
  public async initializeSession() {
    this.registerEventListeners()

    // a newly logged in session MUST BE HANDLED FIRST, even if there is an old existing user
    if (this.hasJustLoggedIn()) {
      this.initializeNewSession()
      return
    }

    const user = await userManager.getUser()

    if (!user) {
      this.signInWithRedirect()
    } else if (user.expired) {
      this.signInSilentOrWithRedirect()
    } else {
      this.resumeSession(user)
    }
  }

  public handle401Unauthorized() {
    this.signInSilentOrWithRedirect()
  }

  public async handleSignOut() {
    try {
      await userManager.removeUser()
    } finally {
      await userManager.signoutRedirect()
    }
  }

  private async signInSilentOrWithRedirect() {
    if (this.isAlreadySigningIn) {
      return
    }

    try {
      this.isAlreadySigningIn = true
      await userManager.signinSilent()
    } catch (err) {
      await this.signInWithRedirect()
    } finally {
      this.isAlreadySigningIn = false
    }
  }

  private async signInWithRedirect() {
    return await userManager.signinRedirect({
      url_state: document.location.pathname + document.location.search,
    })
  }

  private registerEventListeners() {
    // called via `signinCallback` and `signinSilent`
    userManager.events.addUserLoaded((user) => this.saveUserInAppState(user))
  }

  private resumeSession(user: User) {
    this.saveUserInAppState(user)
  }

  private async initializeNewSession() {
    try {
      await userManager.signinCallback()
    } catch (authenticationError) {
      console.error({ authenticationError })
      this.signInWithRedirect()
    }
  }

  private hasJustLoggedIn() {
    return new URLSearchParams(document.location.search).has('code')
  }

  private saveUserInAppState(user: User) {
    setUserInfo({
      id: `${user.profile.sub}`,
      name: `${user.profile.name}`,
      givenName: `${user.profile.given_name}`,
      token: `${user.access_token}`,
      url_state: user.url_state || '/', // <Authentication /> will redirect the user based on url_state
    })
  }
}

export const authManager = new AuthManger()
