import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/kpi'
import { configureApiClient } from '../clientConfig'

configureApiClient(OpenAPI, GlobalConfig.kpi.REST_API_URL)

export * as KpisService from '../../generated/kpi/services.gen'

export { type KpiDto, type KpiDtoCollectionResponse } from '../../generated/kpi'

export type KpisApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
