import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import {
  CanvasWidget,
  SelectionBoxLayerFactory,
  ZoomCanvasAction,
} from '@projectstorm/react-canvas-core'
import {
  DagreEngine,
  DefaultDiagramState,
  DefaultLabelFactory,
  DefaultPortFactory,
  DiagramEngine,
  DiagramModel,
  LinkLayerFactory,
  NodeLayerFactory,
  NodeModel,
} from '@projectstorm/react-diagrams'
import React from 'react'

import { CurvedLinkFactory } from './ProcessingGraphNodes/CurvedLink/CurvedLinkFactory'
import { CurvedLinkModel } from './ProcessingGraphNodes/CurvedLink/CurvedLinkModel'
import { InputNodeFactory } from './ProcessingGraphNodes/InputNode/InputNodeFactory'
import { InputNodeModel } from './ProcessingGraphNodes/InputNode/InputNodeModel'
import {
  KpiGraphMode,
  KpiNodeFactory,
} from './ProcessingGraphNodes/KpiNodes/KpiNodeFactory'
import { KpiNodeModel } from './ProcessingGraphNodes/KpiNodes/KpiNodeModel'
import { OrderedPortModel } from './ProcessingGraphNodes/OrderedPortModel/OrderedPortModel'
import { ProcessingObjectNodeFactory } from './ProcessingGraphNodes/ProcessingObjectNode/ProcessingObjectNodeFactory'
import { ProcessingObjectNodeModel } from './ProcessingGraphNodes/ProcessingObjectNode/ProcessingObjectNodeModel'
import { ProcessingObjectNodeContentProps } from './ProcessingGraphNodes/ProcessingObjectNode/ProcessingObjectNodeWidget'
import ProcessingGraphOverlay from './ProcessingGraphOverlay'
import ProcessingObjectSelectionProvider from './ProcessingObjectSelectionContext'
import { isStreamNode } from './graphUtils'
import { KpiGraph, getNodeInputsOutputs } from './kpiGraphTypes'
import {
  ProcessingObject,
  ProcessingObjectLink,
  SelectedProcessingObjectInput,
} from './processingGraphTypes'

const GraphContainer = styled.div`
  background-color: ${colors.gray_20};

  position: relative;

  height: 100%;
  display: flex;
  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
`

export interface ProcessingGraphDiagramProps<TNodeData> {
  processingObjects: ProcessingObject[]
  links: ProcessingObjectLink[]
  selectedProcessingObjects: SelectedProcessingObjectInput[]
  onRegisterNodeEvents?: (
    node: NodeModel,
    processingObject: ProcessingObject
  ) => void
  processingObjectNodeContentComponent: React.FC<
    ProcessingObjectNodeContentProps<TNodeData>
  >
  getNodeData: (nodeId: string) => TNodeData
  kpiGraph?: KpiGraph
  mode: KpiGraphMode
}

const emptyGraph: KpiGraph = Object.freeze({ nodes: {}, links: [] })

export const ProcessingGraphDiagram = <TNodeData,>({
  processingObjects,
  links,
  selectedProcessingObjects,
  onRegisterNodeEvents,
  processingObjectNodeContentComponent,
  getNodeData,
  // The default value for this parameter must be a constant value, otherwise it will trigger updates on React hooks
  kpiGraph = emptyGraph,
  mode,
}: ProcessingGraphDiagramProps<TNodeData>) => {
  const dagreEngine = React.useMemo(() => {
    const dagreEngine = new DagreEngine({
      graph: {
        rankdir: 'LR',
        ranker: 'longest-path',
        ranksep: 96,
      },
    })

    return dagreEngine
  }, [])

  const engine = React.useMemo(() => {
    const engine = new DiagramEngine({
      registerDefaultZoomCanvasAction: false,
    })
    engine.getLayerFactories().registerFactory(new NodeLayerFactory() as any)
    engine.getLayerFactories().registerFactory(new LinkLayerFactory() as any)
    engine.getLayerFactories().registerFactory(new SelectionBoxLayerFactory())

    engine.getLabelFactories().registerFactory(new DefaultLabelFactory())
    engine.getPortFactories().registerFactory(new DefaultPortFactory())

    engine.getStateMachine().pushState(new DefaultDiagramState())

    engine
      .getActionEventBus()
      .registerAction(new ZoomCanvasAction({ inverseZoom: true }))

    const nodeFactories = engine.getNodeFactories()
    nodeFactories.registerFactory(new InputNodeFactory())
    nodeFactories.registerFactory(
      new ProcessingObjectNodeFactory(processingObjectNodeContentComponent)
    )
    nodeFactories.registerFactory(new KpiNodeFactory(mode))

    const linkFactories = engine.getLinkFactories()
    linkFactories.registerFactory(new CurvedLinkFactory())

    return engine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const model = React.useMemo(() => {
    const model = new DiagramModel()
    model.setLocked(true)

    // Create nodes
    const nodes: Record<string, NodeModel> = {}
    processingObjects.forEach((processingObject) => {
      const nodeModel = isStreamNode(processingObject)
        ? new InputNodeModel(processingObject)
        : new ProcessingObjectNodeModel(processingObject)

      model.addNode(nodeModel)
      nodes[processingObject.nodeId] = nodeModel
    })

    Object.keys(kpiGraph.nodes).forEach((kpiName) => {
      const kpiNode = kpiGraph.nodes[kpiName]
      const nodeModel = new KpiNodeModel(kpiName)

      model.addNode(nodeModel)
      nodes[kpiName] = nodeModel

      const { inputs, outputs } = getNodeInputsOutputs(kpiNode)

      inputs.forEach((input, index) => {
        nodeModel.addPort(
          new OrderedPortModel({
            name: input.name,
            in: true,
            order: index,
          })
        )
      })
      outputs.forEach((output, index) => {
        nodeModel.addPort(
          new OrderedPortModel({
            name: output.name,
            order: index,
          })
        )
      })
    })

    // Create links
    const createLinkModel = (source: string, target: string) => {
      const sourceNode = nodes[source]
      const targetNode = nodes[target]

      if (!sourceNode || !targetNode) {
        return
      }

      const outNodeName = 'out'
      const inNodeName = 'in'
      const portOut =
        sourceNode.getPort(outNodeName) ??
        sourceNode.addPort(
          new OrderedPortModel({
            name: outNodeName,
            label: '',
            order: 0,
          })
        )
      const portIn =
        targetNode.getPort(inNodeName) ??
        targetNode.addPort(
          new OrderedPortModel({
            name: inNodeName,
            label: '',
            in: true,
            order: 0,
          })
        )
      const linkModel = new CurvedLinkModel()
      linkModel.setSourcePort(portOut)
      linkModel.setTargetPort(portIn)

      model.addLink(linkModel)
    }

    links.forEach((link) => createLinkModel(link.source, link.target))
    kpiGraph.links.forEach((link) => {
      const portOut = nodes[link.source]?.getPort(link.sourceOutput)
      const portIn = nodes[link.target]?.getPort(link.targetInput)

      if (!portOut || !portIn) {
        // This should never happen because all graphs are validated
        console.error('Graph contains an invalid link.', link)
        return
      }

      const linkModel = new CurvedLinkModel()
      linkModel.setSourcePort(portOut)
      linkModel.setTargetPort(portIn)

      model.addLink(linkModel)
    })

    engine.setModel(model)
    return model
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine])

  React.useMemo<undefined>(() => {
    model.getNodes().forEach((node) => {
      node.clearListeners()

      onRegisterNodeEvents &&
        onRegisterNodeEvents(
          node,
          (node as InputNodeModel | ProcessingObjectNodeModel).processingObject
        )
    })
  }, [model, onRegisterNodeEvents])

  const boxRef = React.useRef<HTMLDivElement>(null)

  const zoomToFit = React.useCallback(() => {
    const graphBounds = engine.getBoundingNodesRect(model.getNodes())
    const graphPoints = graphBounds.getPoints()
    const minX = Math.min(...graphPoints.map((d) => d.x))
    const minY = Math.min(...graphPoints.map((d) => d.y))
    const maxX = Math.max(...graphPoints.map((d) => d.x))
    const maxY = Math.max(...graphPoints.map((d) => d.y))

    const canvas = engine.getCanvas().getBoundingClientRect()

    const margin = 32
    const graphWidth = maxX - minX
    const boundingWidth = Math.max(graphWidth, 800)
    const boundingHeight = maxY - minY
    const zoomFactorX = (canvas.width - 2 * margin) / boundingWidth
    const zoomFactorY = (canvas.height - 2 * margin) / boundingHeight
    const zoomFactor = Math.min(1, zoomFactorX, zoomFactorY)

    model.setOffsetX(
      minX * zoomFactor + canvas.width / 2 - (boundingWidth * zoomFactor) / 2
    )
    model.setOffsetY(
      minY * zoomFactor + canvas.height / 2 - (boundingHeight * zoomFactor) / 2
    )
    model.setZoomLevel(zoomFactor * 100)

    engine.repaintCanvas()
  }, [model, engine])

  const [isLayouted, setIsLayouted] = React.useState(false)

  React.useEffect(() => {
    /* When viewed through Remote Desktop the positions of node ports are not calculated properly on first render, messing up the link lines.
       Layouting after a small timeout fixes this problem and is also recommended by the docs.
    */
    setTimeout(() => {
      if (!engine.getCanvas()) {
        // When the user leaves the page too quickly (happens in cypress tests) the canvas is no longer available before layouting is called.
        return
      }

      // Auto layout
      dagreEngine.redistribute(model)

      zoomToFit()
      setIsLayouted(true)
    }, 50)
  }, [dagreEngine, engine, model, zoomToFit])

  React.useEffect(() => {
    if (boxRef.current) {
      const preventScrolling = (event: WheelEvent) => event.preventDefault()

      const box = boxRef.current
      box.addEventListener('wheel', preventScrolling)

      return () => box.removeEventListener('wheel', preventScrolling)
    }
  })

  return (
    <ProcessingObjectSelectionProvider
      links={links}
      selectedProcessingObjects={selectedProcessingObjects}
      getNodeData={getNodeData}
    >
      <GraphContainer ref={boxRef} data-testid="processing_graph">
        <CanvasWidget engine={engine} />
        <ProcessingGraphOverlay
          engine={engine}
          model={model}
          zoomToFit={zoomToFit}
          boxRef={boxRef}
        />
        {!isLayouted && (
          <Box
            component="div"
            width="100%"
            height="100%"
            style={{ backgroundColor: colors.gray_20 }}
            position="absolute"
          />
        )}
      </GraphContainer>
    </ProcessingObjectSelectionProvider>
  )
}
