import { useUserInfo } from '@simphera/shared/state'
import React from 'react'
import { Navigate } from 'react-router-dom'

/** this will only render after userinfo exists. we can then safely navigate the user to the page they wanted to access */
export const Authentication: React.FC = () => {
  const userInfo = useUserInfo()

  // if a full URL is provided, redirect to that (external) URL (i.e. used to redirect to /sut-te-editor plugin)
  if (userInfo.url_state.startsWith('http')) {
    document.location.replace(userInfo.url_state)
    return null
  }

  // otherwise, navigate to the route
  return <Navigate to={userInfo.url_state} replace />
}
