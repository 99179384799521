import { AdditionalLinkEntry, GlobalConfig } from '@simphera/shared/appconfig'

export const getAdditionalLinks = (
  id: string | undefined
): Array<AdditionalLinkEntry> => {
  if (!id) {
    return []
  }

  const linksForId = GlobalConfig.additionalLinks.find(
    (c) => c.identifier === id
  )

  return Array.isArray(linksForId?.entries) ? linksForId!.entries : []
}
