import { colors, theme } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

export const ResultMetadataLayout = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 64px) auto;
  grid-template-columns: repeat(3, 160px) minmax(0, auto) auto;
  grid-template-areas:
    'stateValue verdictReasonOrProgressValue verdictReasonOrProgressValue verdictReasonOrProgressValue verdictReasonOrProgressValue'
    'startedValue finishedValue executionTimeValue executionNodeValue jobPriorityValue'
    'downloadsValue downloadsValue downloadsValue downloadsValue .';

  ${theme.breakpoints.down('lg')} {
    grid-template-rows: repeat(3, 64px) auto;
    grid-template-columns: repeat(2, 160px) auto;
    grid-template-areas:
      'stateValue verdictReasonOrProgressValue verdictReasonOrProgressValue'
      'startedValue finishedValue .'
      'executionTimeValue executionNodeValue jobPriorityValue'
      'downloadsValue downloadsValue downloadsValue';
  }

  column-gap: 16px;
`

export const ResultMetadataCell = styled.div<{ $cellName: string }>`
  grid-area: ${({ $cellName }) => $cellName};
`

export const ResultMetadataCellContentWithHelp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const ResultMetadataCellLabel = styled.div`
  white-space: nowrap;
  color: ${colors.gray_70};
  font-size: 14px;
`

export const ResultMetadataProgressCell = styled(ResultMetadataCell)`
  position: relative;
`

export const ResultEmbeddedLiveVideoGridCell = styled(ResultMetadataCell)`
  max-height: 175px;
`
