import { v4 as uuidv4 } from 'uuid'
import { GitWorkspaceResponse, GitWorkspaceService } from '../..'
import { jsonPatch } from '../../utils/jsonPatch'

export interface PatchGitWorkspaceParameters {
  name: string
  description: string
  repositoryUrl: string
  repositoryUsername: string
  repositoryPassword: string
  pathToProjectYaml: string
  checkoutFolders: string
  lfsPassword: string
  lfsUsername: string
}

export const convertCommaSeparatedListToArray = (
  str: string = ''
): Array<string> =>
  str
    .replaceAll(/\n/g, ',') // put all lines on one line with comma-separated values
    .split(',') // split values
    .map((line) => line.trim()) // trim values
    .filter(Boolean) // filter out empty values

export const convertArrayToCommaSeparatedList = (arr: Array<string>): string =>
  arr.join(',\n')

export const createGitWorkspace = async (
  params: PatchGitWorkspaceParameters
): Promise<GitWorkspaceResponse> => {
  const { add, getOperations } = jsonPatch()

  add('name', params.name)
  add('description', params.description)
  add('gitConfiguration', {
    repositoryUrl: params.repositoryUrl,
    username: params.repositoryUsername,
    password: params.repositoryPassword,
    pathToProjectYaml: params.pathToProjectYaml,
    lfsConfiguration: {
      username: params.lfsUsername,
      password: params.lfsPassword,
    },
    checkoutFolders: convertCommaSeparatedListToArray(params.checkoutFolders),
  })

  return GitWorkspaceService.patchGitWorkspacesByGitWorkspaceId({
    gitWorkspaceId: uuidv4(),
    requestBody: getOperations(),
  })
}

export const editGitWorkspace = async (
  id: string,
  params: PatchGitWorkspaceParameters,
  updateRepositoryPassword: boolean = true,
  updateLfsPassword: boolean = true
): Promise<GitWorkspaceResponse> => {
  const { add, getOperations } = jsonPatch()

  add('name', params.name)
  add('description', params.description)
  add('gitConfiguration/repositoryUrl', params.repositoryUrl)
  add('gitConfiguration/username', params.repositoryUsername)
  add('gitConfiguration/pathToProjectYaml', params.pathToProjectYaml)
  add('gitConfiguration/lfsConfiguration/username', params.lfsUsername)
  add(
    'gitConfiguration/checkoutFolders',
    convertCommaSeparatedListToArray(params.checkoutFolders)
  )

  if (updateRepositoryPassword) {
    add('gitConfiguration/password', params.repositoryPassword)
  }

  if (updateLfsPassword) {
    add('gitConfiguration/lfsConfiguration/password', params.lfsPassword)
  }

  return GitWorkspaceService.patchGitWorkspacesByGitWorkspaceId({
    gitWorkspaceId: id,
    requestBody: getOperations(),
  })
}
