import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getHeaders } from './getHeaders'
import { handleError } from './handleError'

export function createApolloClient(uri: string) {
  const link = createUploadLink({ uri })
  const authLink = setContext(getHeaders)
  const errorLink = onError((error: ErrorResponse) => handleError(error))

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, authLink.concat(link)]),
  })
}
