import { authManager, getBearerToken } from '@simphera/shared/state'
import { HttpStatusCode } from 'axios'
import { OpenAPIConfig } from '../generated/kpi'

export const configureApiClient = (
  openAPIConfig: OpenAPIConfig,
  baseUrl: string
) => {
  openAPIConfig.BASE = baseUrl

  openAPIConfig.interceptors.request.use((request) => {
    const headers = new Headers(request.headers)
    headers.append('Authorization', getBearerToken())
    request.headers = headers
    return request
  })

  openAPIConfig.interceptors.response.use((response) => {
    if (response.status === HttpStatusCode.Unauthorized) {
      authManager.handle401Unauthorized()
    }

    return response
  })
}
