import { createProviderHook } from '@simphera/shared/ui-simphera'
import React from 'react'
import { KpiVisualizationProperty } from '../../KpiVisualiziationTypes'
import { VideoTimeCursorProvider } from '../../VideoDisplay/VideoTimeCursorProvider'
import TimelineEventHub from './TimelineEventHub'
import { getPropertiesTimelineRange } from './getPropertiesTimelineRange'

export interface TimelineRange {
  minValue: number
  maxValue: number
}

export interface TimelineRangeContextData {
  timelineRange: TimelineRange
  addToTimelineRange: (addedRange: TimelineRange) => void
  currentRange: TimelineRange
  setCurrentRange: (newRange: TimelineRange) => void
  cursorPosition: number | undefined
  setCursorPosition: (cursorPosition: number | undefined) => void

  /** FOR TIMELINE NAVIGATION ONLY. Always provides the latest set display range.
   * Required for browser events that are called multiple times in one render cycle, so the display range from the React State would be outdated in every event call after the first. */
  currentRangeRef: React.MutableRefObject<TimelineRange>
}

const TimelineRangeContext = React.createContext<
  TimelineRangeContextData | undefined
>(undefined)

interface TimelineRangeProviderProps {
  properties: KpiVisualizationProperty[]
}

const TimelineRangeProvider: React.FC<
  React.PropsWithChildren<TimelineRangeProviderProps>
> = ({ properties, children }) => {
  const [timelineRange, setTimelineRange] = React.useState(() => {
    const propertiesTimelineRange = getPropertiesTimelineRange(properties)

    if (propertiesTimelineRange === undefined) {
      return {
        minValue: 0,
        maxValue: 1,
      }
    } else if (
      propertiesTimelineRange.minValue === propertiesTimelineRange.maxValue
    ) {
      const timestamp = propertiesTimelineRange.minValue
      const start = timestamp - 1
      if (start < 0) {
        return {
          minValue: 0,
          maxValue: 1,
        }
      } else {
        return {
          minValue: start,
          maxValue: timestamp + 1,
        }
      }
    }

    return propertiesTimelineRange
  })

  const currentRangeRef = React.useRef<TimelineRange>(timelineRange)
  const [currentRange, setCurrentRange] =
    React.useState<TimelineRange>(timelineRange)
  const [cursorPosition, setCursorPosition] = React.useState<
    number | undefined
  >(undefined)

  const updateCurrentRange = React.useCallback((newRange: TimelineRange) => {
    currentRangeRef.current = newRange
    setCurrentRange(newRange)
  }, [])

  const addToTimelineRange = React.useCallback((addedRange: TimelineRange) => {
    setTimelineRange((currentTimelineRange) => {
      const newTimelineRange: TimelineRange = {
        minValue: Math.min(currentTimelineRange.minValue, addedRange.minValue),
        maxValue: Math.max(currentTimelineRange.maxValue, addedRange.maxValue),
      }

      // Update currently displayed range to new max range if it is not currently zoomed in
      setCurrentRange((currentRange) =>
        currentRange.minValue === currentTimelineRange.minValue &&
        currentRange.maxValue === currentTimelineRange.maxValue
          ? newTimelineRange
          : currentRange
      )

      return newTimelineRange
    })
  }, [])

  return (
    <TimelineRangeContext.Provider
      value={{
        timelineRange,
        addToTimelineRange,
        currentRange,
        currentRangeRef,
        setCurrentRange: updateCurrentRange,
        cursorPosition,
        setCursorPosition,
      }}
    >
      <VideoTimeCursorProvider>
        <TimelineEventHub>{children}</TimelineEventHub>
      </VideoTimeCursorProvider>
    </TimelineRangeContext.Provider>
  )
}

export const useTimelineRange = createProviderHook(
  TimelineRangeContext,
  'TimelineRangeProvider',
  'useTimelineRange'
)

export default TimelineRangeProvider
