import React, { useEffect } from 'react'
import { useUserInfo } from '../hooks'
import { DelayedSpinner } from './DelayedSpinner'
import { authManager } from './authManager'

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const userInfo = useUserInfo()
  const didEffectRun = React.useRef(false)

  useEffect(() => {
    // note: required for strict mode (dev use case) to make sure we only initialize the session ONCE
    if (!didEffectRun.current) {
      authManager.initializeSession()
    }

    return () => {
      didEffectRun.current = true
    }
  }, [])

  return userInfo ? children : <DelayedSpinner />
}
