import { Button, colors, showSnackbar } from '@dspace-internal/ui-kit'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined'
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Popover,
  Typography,
} from '@mui/material'
import { Operation } from '@simphera/shared/rest-clients'
import {
  scrollToElementBottom,
  usePollingInterval,
} from '@simphera/shared/ui-simphera'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NotificationDialogRenderer } from './NotificationDialogRenderer'
import { NotificationListItem } from './NotificationListItem'
import { getHasUnreadNotifications, getNotifications } from './api'
import {
  getLastTimeNotificationsWereRead,
  persistLastTimeNotificationsWereRead,
} from './storage'
import {
  NotificationHeader,
  NotificationList,
  PopoverStyles,
  SmallSpinner,
} from './styles'

const NOTIFICATIONS_POLL_MS = 10000
const NOTIFICATIONS_PAGE_SIZE = 5

export const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [lastTimeNotificationsWereRead, setLastTimeNotificationsWereRead] =
    useState(getLastTimeNotificationsWereRead())
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const [notifications, setNotifications] = useState<Array<Operation>>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isNextPageLoading, setIsNextPageLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [areAllNotificationsLoaded, setAreAllNotificationsLoaded] =
    useState(false)

  const listRef = useRef(null)

  const isDialogOpen = Boolean(anchorEl)
  const dialogId = isDialogOpen ? 'notificationsDialog' : undefined

  usePollingInterval(
    () => {
      if (hasUnreadNotifications) {
        // we already know that we have unread notifications. no need to poll again
        return
      }

      getHasUnreadNotifications(lastTimeNotificationsWereRead).then(
        setHasUnreadNotifications
      )
    },
    NOTIFICATIONS_POLL_MS,
    true
  )

  const openDialog: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeDialog = () => {
    setAnchorEl(null)
  }

  const markAllAsRead = () => {
    const timestamp = Date.now()
    setLastTimeNotificationsWereRead(timestamp)
    persistLastTimeNotificationsWereRead(timestamp)
    setHasUnreadNotifications(false)
  }

  const loadFirstPage = useCallback(async () => {
    setIsLoading(true)
    setIsNextPageLoading(false)
    setPage(0)
    setAreAllNotificationsLoaded(false)

    try {
      const items = await getNotifications(0, NOTIFICATIONS_PAGE_SIZE)
      setNotifications(items)
    } catch {
      showSnackbar({
        message: 'Could not fetch notifications',
        appearance: 'error',
      })
    }

    setIsLoading(false)
  }, [])

  const loadNextPage = useCallback(async () => {
    setIsNextPageLoading(true)

    const nextPage = page + 1
    const newNotifications: Array<Operation> = []

    try {
      const items = await getNotifications(nextPage, NOTIFICATIONS_PAGE_SIZE)
      newNotifications.push(...items)
    } catch {
      showSnackbar({
        message: 'Could not fetch notifications',
        appearance: 'error',
      })
    }

    setNotifications((oldNotifications) => [
      ...oldNotifications,
      ...newNotifications,
    ])
    setPage(nextPage)
    setAreAllNotificationsLoaded(
      newNotifications.length < NOTIFICATIONS_PAGE_SIZE
    )
    setIsNextPageLoading(false)

    setTimeout(() => {
      // note: timeout to scroll down after list has rerendered
      if (listRef.current) {
        scrollToElementBottom(listRef.current, 'smooth')
      }
    })
  }, [page])

  // fetch notifications when dialog opens
  useEffect(() => {
    if (!isDialogOpen) {
      return
    }

    loadFirstPage()
  }, [isDialogOpen, loadFirstPage])

  return (
    <>
      <NotificationDialogRenderer />
      <IconButton
        aria-controls={isDialogOpen ? dialogId : undefined}
        aria-haspopup="true"
        aria-expanded={isDialogOpen ? 'true' : undefined}
        onClick={openDialog}
        aria-label="Notifications"
        sx={{ color: colors.gray_80 }}
      >
        <Badge
          invisible={!hasUnreadNotifications}
          color="error"
          variant="dot"
          overlap="circular"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={isDialogOpen}
        id={dialogId}
        onClose={closeDialog}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={PopoverStyles}
      >
        <NotificationHeader>
          <Typography variant="subtitle2">Notifications</Typography>
          {hasUnreadNotifications && !isLoading && (
            <Button
              type="button"
              onClick={markAllAsRead}
              label="Mark all as read"
              variant="text"
              size="small"
              color="primary"
            />
          )}
        </NotificationHeader>
        {/* LOADING STATE */}
        {isLoading && (
          <Box component="div" padding="8px 16px">
            <SmallSpinner />
          </Box>
        )}

        {/* EMPTY STATE */}
        {!isLoading && notifications.length === 0 && (
          <Box component="div" padding="8px 16px">
            <Typography variant="body2">No notifications</Typography>
          </Box>
        )}

        {/* DATA STATE */}
        {!isLoading && (
          <>
            <NotificationList ref={listRef}>
              {notifications.map((operation) => (
                <div key={operation.id}>
                  <Divider />
                  <NotificationListItem
                    notification={operation}
                    lastTimeNotificationsWereRead={
                      lastTimeNotificationsWereRead
                    }
                  />
                </div>
              ))}
            </NotificationList>
            {notifications.length > 0 && (
              <Button
                label="Load more"
                onClick={loadNextPage}
                disabled={isNextPageLoading}
                size="small"
                variant="text"
                color="primary"
                sx={{
                  borderRadius: 0,
                  display: areAllNotificationsLoaded ? 'none' : 'block',
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              />
            )}
          </>
        )}
      </Popover>
    </>
  )
}
