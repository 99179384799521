import { fmt } from './messageUtils'

export const captions_res = {
  ADD_WIDGET: () => fmt('Add Widget'),
  ALL_WIDGETS: () => fmt('All Widgets'),
  AURELION: () => fmt('AURELION'),
  AURELION_CONFIGURATION: () => fmt('AURELION Configuration'),
  AURELION_STREAMING: () => fmt('Video Stream'),
  BACK: () => fmt('Back'),
  BIRD: () => fmt('Bird'),
  CALIBRATION: () => fmt('Calibration'),
  CAMERA_TARGET: () => fmt('Camera Target'),
  CAMERA_VIEW: () => fmt('Camera View'),
  CHECKBOX: () => fmt('CheckBox'),
  CHECKBOX_SETTINGS: () => fmt('Checkbox Settings'),
  CLONE_EXPERIMENT: () => fmt('Clone Experiment'),
  CONFIGURATION: () => fmt('Configuration'),
  CONTROL: () => fmt('Control'),
  CUSTOM: () => fmt('Custom'),
  DELETE_EXPERIMENT: () => fmt('Delete Experiment'),
  DELETE_EXPERIMENTS: () => fmt('Delete Experiments'),
  DELETE_WIDGET: () => fmt('Delete Widget'),
  DESCRIPTION: () => fmt('Description'),
  DISPLAY: () => fmt('Display'),
  DISPLAY_SETTINGS: () => fmt('Display Settings'),
  EDIT_EXPERIMENT: () => fmt('Edit Experiment'),
  EGO: () => fmt('Ego'),
  EXPERIMENT: () => fmt('Experiment'),
  EXPERIMENT_NOT_FOUND: () => fmt('Experiment not found'),
  EXPERIMENT_SETTINGS: () => fmt('Experiment Settings'),
  EXPERIMENTS: () => fmt('Experiments'),
  FELLOW: () => fmt('Fellow'),
  FRAME: () => fmt('Frame'),
  FRAME_SETTINGS: () => fmt('Frame Settings'),
  INCLUDE_PANEL: () => fmt('Include Selected Panel'),
  INCLUDE_TIMESTAMP: () => fmt('Include Timestamp'),
  LOG_VIEWER_SETTINGS: () => fmt('Log Viewer Settings'),
  LOGS_WIDGET: () => fmt('Log Viewer'),
  LOGS_WIDGET_SETTINGS: () => fmt('Log Viewer Settings'),
  MANEUVER_CONTROL: () => fmt('Maneuver Control'),
  MANEUVER_CONTROL_SHORTEND_TITLE: () => fmt('Maneuver'),
  MANEUVER_TIME: () => fmt('Maneuver Time:'),
  MANEUVER_TIME_SHORT: () => fmt('Man. Time:'),
  MAX: () => fmt('Max'),
  MEASUREMENT_TIME: () => fmt('Measurement Time:'),
  MEASUREMENT_TIME_SHORT: () => fmt('Meas. Time:'),
  MIN: () => fmt('Min'),
  MULTISTATE_LED: () => fmt('Multistate LED'),
  MULTISTATE_LED_SETTINGS: () => fmt('Multistate LED Settings'),
  NAME: () => fmt('Name'),
  NEW_EXPERIMENT: () => fmt('New Experiment'),
  NO_EXPERIMENTS_AVAILABLE: () => fmt('No experiments available'),
  NONE: () => fmt('None'),
  NUMERIC_INPUT: () => fmt('Numeric Input'),
  NUMERIC_INPUT_SETTINGS: () => fmt('Numeric input settings'),
  ORIGIN: () => fmt('Origin'),
  PANEL: () => fmt('Panel'),
  PERMISSION_DENIED: () => fmt('Permission denied'),
  PLOTTER_SETTINGS: () => fmt('Plotter Settings'),
  PUSH_BUTTON: () => fmt('Push Button'),
  PUSH_BUTTON_SETTINGS: () => fmt('Push button settings'),
  RADIO_BUTTON: () => fmt('Radio Button'),
  RADIO_BUTTON_SETTINGS: () => fmt('Radio button settings'),
  SCENARIO: () => fmt('Scenario'),
  SCENARIO_PARAMETERS: () => fmt('Scenario Parameters'),
  SCENARIO_SELECTION: () => fmt('Scenario'),
  SCENE_VIEWER: () => fmt('Scene Viewer'),
  SCENEVIEWER_SETTINGS: () => 'Scene Viewer Settings',
  SELECT_SUT: () => fmt('Select a SUT'),
  SELECT_TEST_ENVIRONMENT: () => fmt('Select a Test Environment'),
  SET_TIME_CURSOR: () => fmt('Set Time Cursor'),
  SETTINGS: () => 'Settings...',
  SETTINGS_LABEL_AUTOMANEUVERSTART: () =>
    fmt('Automatically start maneuver after initializing'),
  SETTINGS_LABEL_TIMEOUT: () => fmt('Automatic experiment shutdown time'),
  SHARE: () => fmt('Share'),
  SHOW_LESS: () => fmt('Show less'),
  SHOW_MORE: () => fmt('Show more'),
  SIDE: () => fmt('Side'),
  SIMULATION_TIME_MMSSFFF: () => fmt('Simulation Time (mm:ss.fff)'),
  SIMULATION_TIME_S: () => fmt('Simulation Time (s)'),
  SMOOTHSCROLLMODE: () => fmt('Smooth Scrolling'),
  STATE: () => fmt('State'),
  STREAM: () => fmt('Stream'),
  STREAM_SETTINGS: () => fmt('Stream Settings'),
  SUT: () => fmt('SUT'),
  SUT_SELECTION: () => fmt('SUT'),
  SYSTEM: () => fmt('System'),
  SYSTEM_UNDER_TEST: () => fmt('System Under Test'),
  TAB: () => fmt('Tab'),
  TE: () => fmt('TE'),
  TE_SELECTION: () => fmt('Test Environment'),
  TEST_ENVIRONMENT: () => fmt('Test Environment'),
  TIME: () => fmt('Time'),
  TIME_CURSOR: () => fmt('Time Cursor'),
  TIME_PLOTTER: () => fmt('Time Plotter'),
  TIME_PLOTTER_NG: () => fmt('New Time Plotter'),
  UNABLE_TO_CLONE_EXPERIMENT: () => fmt('Unable to clone Experiment'),
  UNABLE_TO_DELETE_EXPERIMENT: () => fmt('Unable to delete Experiment'),
  UNABLE_TO_INITIATE_EXPERIMENT: () => fmt('Unable to initiate Experiment'),
  UNABLE_TO_SAVE_EXPERIMENT: () => fmt('Unable to save Experiment'),
  UNABLE_TO_SHUTDOWN_EXPERIMENT: () => fmt('Unable to shut down Experiment'),
  VALUE: () => fmt('Value'),
  VEHICLE: () => fmt('Vehicle'),
  VEHICLE_SELECTION: () => fmt('Vehicle'),
  VISUALIZATION: () => fmt('Visualization'),
  VISUALIZATION_PAUSE: () => fmt('Visualization Pause'),
  VISUALIZATION_PAUSE_SHORTENED_TITLE: () => fmt('Pause'),
  X_AXIS_RANGE: () => fmt('X-Axis Range'),
}
