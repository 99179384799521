import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/import'
import { configureApiClient } from '../clientConfig'

configureApiClient(OpenAPI, GlobalConfig.coreService.REST_API_URL)

export * as ImportService from '../../generated/import/services.gen'

export type ImportApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
