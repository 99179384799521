import { ErrorResponse } from '@apollo/client/link/error'
import HttpStatus from 'http-status-codes'
import { authManager } from '../auth'

export function handleError(error: ErrorResponse) {
  const { operation, graphQLErrors, networkError } = error

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => console.error({ graphQLError }))
  }

  if (networkError) {
    console.error({ networkError })
  }

  if (operation.getContext()?.response?.status === HttpStatus.UNAUTHORIZED) {
    authManager.handle401Unauthorized()
  }
}
