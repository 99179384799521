import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/operation'
import { configureApiClient } from '../clientConfig'

configureApiClient(OpenAPI, GlobalConfig.coreService.REST_API_URL)

export * as OperationsService from '../../generated/operation/services.gen'

export {
  type GetOperationsResponse,
  type Operation,
  type OperationResultList,
  type OperationStatus,
} from '../../generated/operation'

export type OperationsApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
