import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/gitWorkspace'
import { configureApiClient } from '../clientConfig'

configureApiClient(OpenAPI, GlobalConfig.project.REST_API_URL)

export * as GitWorkspaceService from '../../generated/gitWorkspace/services.gen'

export { type GitWorkspaceResponseDto as GitWorkspaceResponse } from '../../generated/gitWorkspace'

export type GitWorkspaceApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}

export * from './utils'
