import { traceparent } from 'tctx'
import { getBearerToken } from '../utils/getBearerToken'

export async function getHeaders() {
  const traceId = traceparent.make().toString()

  return {
    headers: {
      authorization: getBearerToken(),
      'x-request-id': traceId,
      traceparent: traceId,
    },
  }
}
