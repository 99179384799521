import { GlobalConfig } from '@simphera/shared/appconfig'
import { getUserInfo } from '@simphera/shared/state'
import { ApiError, OpenAPI, RestApiError } from '../../generated/authmanager'

// manually adding /authentication to BASE_URL. why? idk, but it's necessary to make it work!
OpenAPI.BASE = GlobalConfig.coreService.REST_API_URL + '/authentication'
OpenAPI.TOKEN = async () => getUserInfo()?.token as string

export * as ApiKeyService from '../../generated/authmanager/services.gen'

export { type ApiKeyDTO as ApiKey } from '../../generated/authmanager'

export type AuthManagerApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
