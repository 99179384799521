import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/stepbt'

import { configureApiClient } from '../clientConfig'

configureApiClient(OpenAPI, GlobalConfig.stepbt.REST_API_URL)

export * as StepbtService from '../../generated/stepbt/services.gen'

export {
  type CustomAttributeDTO,
  type PaginatedCustomAttributes,
  type PatchCustomAttributesDTO,
  type RunDTO,
  type RunDTOPaginatedCollectionResponse,
  type UserDto,
} from '../../generated/stepbt'

export type StepbtManagerApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
