import {
  HistogramProperty,
  KpiVisualizationProperty,
  LinePlotProperty,
  ScatterPlotProperty,
  TimelineBase,
} from '../../KpiVisualiziationTypes'
import { TimelineRange } from './TimelineRangeProvider'

const getTimelineTimelineRange = (
  property: TimelineBase<unknown, unknown>
): TimelineRange | undefined => {
  if (property.data.length > 0) {
    const range: TimelineRange = {
      minValue: Number.MAX_VALUE,
      maxValue: Number.MIN_VALUE,
    }

    property.data.forEach((entry) => {
      if (entry.start < range.minValue) {
        range.minValue = entry.start
      }

      const latestTimestamp = entry.end ?? entry.start
      if (latestTimestamp > range.maxValue) {
        range.maxValue = latestTimestamp
      }
    })

    return range
  } else {
    return undefined
  }
}

const getHistogramTimelineRange = (
  property: HistogramProperty
): TimelineRange | undefined => {
  if (
    property.series.length === 0 ||
    property.series.every((serie) => serie.bins.length === 0)
  ) {
    return undefined
  }

  const range: TimelineRange = {
    minValue: Number.MAX_VALUE,
    maxValue: Number.MIN_VALUE,
  }

  property.series.forEach((serie) => {
    serie.bins.forEach((bin) => {
      if (bin.min < range.minValue) {
        range.minValue = bin.min
      }
      if (bin.max > range.maxValue) {
        range.maxValue = bin.max
      }
    })
  })

  return range
}

const getPointPlotTimelineRange = (
  property: LinePlotProperty | ScatterPlotProperty
): TimelineRange | undefined => {
  if (
    property.series.length === 0 ||
    property.series.every((serie) => serie.points.length === 0)
  ) {
    return undefined
  }

  const range: TimelineRange = {
    minValue: Number.MAX_VALUE,
    maxValue: Number.MIN_VALUE,
  }

  property.series.forEach((serie) => {
    serie.points.forEach((point) => {
      if (point.x < range.minValue) {
        range.minValue = point.x
      }
      if (point.x > range.maxValue) {
        range.maxValue = point.x
      }
    })
  })

  return range
}

const getTimelineRangeMapping: Record<
  KpiVisualizationProperty['type'],
  ((property: any) => TimelineRange | undefined) | undefined
> = {
  Group: undefined,
  Subheader: undefined,
  String: undefined,
  Tile: undefined,
  VerdictTimeline: getTimelineTimelineRange,
  StringTimeline: getTimelineTimelineRange,
  Histogram: getHistogramTimelineRange,
  LinePlot: getPointPlotTimelineRange,
  ScatterPlot: getPointPlotTimelineRange,
  BarChart: undefined,
  Video: undefined,
  Reference: undefined,
}

export const getPropertiesTimelineRange = (
  properties: KpiVisualizationProperty[]
): TimelineRange | undefined => {
  if (properties.length > 0) {
    let totalRange: TimelineRange | undefined = undefined

    properties.forEach((property) => {
      const getRange = getTimelineRangeMapping[property.type]
      if (!getRange) {
        return
      }

      const propertyRange = getRange(property)
      if (propertyRange === undefined) {
        return
      }

      if (totalRange === undefined) {
        totalRange = propertyRange
      }

      if (propertyRange.minValue < totalRange.minValue) {
        totalRange.minValue = propertyRange.minValue
      }
      if (propertyRange.maxValue > totalRange.maxValue) {
        totalRange.maxValue = propertyRange.maxValue
      }
    })

    return totalRange
  } else {
    return undefined
  }
}
