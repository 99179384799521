import { getUserInfo } from '../state'

export function getBearerToken() {
  const token = getUserInfo()?.token

  if (!token) {
    console.error('token is not available yet')
  }

  return `Bearer ${token}`
}
