import { DataGrid, DataGridColumn } from '@dspace-internal/ui-kit'
import { mdiSourceBranch, mdiSourceCommit } from '@mdi/js'
import Icon from '@mdi/react'
import { Box, Typography } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid-pro'
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  RelativeTime,
} from '@simphera/shared/ui-simphera'
import React, { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  GitWorkspaceCommitsQuery,
  Project,
  QuerySettingsInput,
} from '../../../../generated/codeGenerator'

interface GitWorkspaceCommit {
  branchName: string
  commitDate: Date
  commitId: string
  commitMessage: string
  commitUser: string
  projectCreatedOn: Date | undefined
  projectCreatedBy: string
  projectId: string
  projectName: string
}

interface GitWorkspaceListProps {
  commitsData: GitWorkspaceCommitsQuery | undefined
  commitsQuerySettings: QuerySettingsInput
  loading: boolean
  setCommitsQuerySettings: Dispatch<SetStateAction<QuerySettingsInput>>
}

const columns: Array<DataGridColumn<GitWorkspaceCommit>> = [
  {
    flex: 1,
    sortable: false,
    field: 'commit',
    headerName: 'Commit',
    renderCell: ({ row }) => {
      const shortenedCommitId = row.commitId.substring(0, 8)

      return (
        <Box
          component="div"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {row.commitMessage}
          </Typography>
          <Box component="div" display="flex" alignItems="center" gap={1}>
            <Icon path={mdiSourceCommit} size={0.6} style={{ flexShrink: 0 }} />
            {shortenedCommitId} committed
            <RelativeTime date={new Date(row.commitDate)} /> by {row.commitUser}
          </Box>
        </Box>
      )
    },
  },
  {
    flex: 1,
    sortable: false,
    field: 'branch',
    headerName: 'Branch or Tag',
    renderCell: ({ row }) => {
      return (
        <Box component="div" display="flex" alignItems="center" gap={1}>
          <Icon path={mdiSourceBranch} size={0.6} />
          {row.branchName}
        </Box>
      )
    },
  },
  {
    flex: 1,
    sortable: false,
    field: 'project',
    headerName: 'Import',
    renderCell: ({ row }) => {
      return (
        <div>
          {row.projectCreatedOn && (
            <RelativeTime date={new Date(row.projectCreatedOn)} />
          )}
          {row.projectCreatedBy && <span> by {row.projectCreatedBy}</span>}
        </div>
      )
    },
  },
]

export const GitWorkspaceCommitsTable: React.FC<GitWorkspaceListProps> = ({
  commitsData,
  commitsQuerySettings,
  loading,
  setCommitsQuerySettings,
}) => {
  const navigate = useNavigate()
  const totalCount = commitsData?.commitsOfWorkspace?.totalCount || 0

  const commits: Array<Project> =
    commitsData?.commitsOfWorkspace?.edges?.map((e) => e.node) || []

  const rows: Array<GitWorkspaceCommit> = commits.map((c) => ({
    branchName: c.branch || '',
    commitDate: c.commit?.date || '',
    commitId: c.commit?.commitId || '',
    commitMessage: c.commit?.message || '',
    commitUser: c.commit?.authorName || '',
    projectCreatedOn: c.createdOn || undefined,
    projectCreatedBy: c.createdBy?.name || '',
    projectId: c.id,
    projectName: c.name,
  }))

  const goToProject = (params: GridRowParams<GitWorkspaceCommit>): void => {
    navigate(`/projects/${params.row.projectId}`)
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      disableColumnFilter
      disableColumnPinning
      disableColumnSelector
      disableRowSelectionOnClick
      disableColumnMenu
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.projectId}
      loading={loading}
      sx={{
        '.MuiDataGrid-row': { cursor: 'pointer' },
      }}
      onRowClick={goToProject}
      pagination={true}
      paginationMode="server"
      paginationModel={{
        page: Number(commitsQuerySettings.pageToken),
        pageSize: commitsQuerySettings.pageSize,
      }}
      onPaginationModelChange={(model) =>
        setCommitsQuerySettings({
          ...commitsQuerySettings,
          pageToken: model.page.toString(),
          pageSize: model.pageSize,
        })
      }
      rowCount={totalCount}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
    />
  )
}
