/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Commit = {
  __typename?: 'Commit';
  authorEmail?: Maybe<Scalars['String']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  commitId?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CommitsOfWorkspaceConnection = {
  __typename?: 'CommitsOfWorkspaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CommitsOfWorkspaceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommitsOfWorkspaceEdge = {
  __typename?: 'CommitsOfWorkspaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Project;
};

export type DeleteProjectInput = {
  id: Scalars['ID']['input'];
};

export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Error = {
  message: Scalars['String']['output'];
};

export type GetProjectsOfWorkspaceInput = {
  gitWorkspaceId: Scalars['ID']['input'];
  querySettings: QuerySettingsInput;
};

export type GitConfiguration = {
  __typename?: 'GitConfiguration';
  checkoutFolders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pathToProjectYaml?: Maybe<Scalars['String']['output']>;
  repositoryUrl?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type GitWorkspace = {
  __typename?: 'GitWorkspace';
  createdBy?: Maybe<User>;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  gitConfiguration: GitConfiguration;
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type GitWorkspacesConnection = {
  __typename?: 'GitWorkspacesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<GitWorkspacesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GitWorkspace>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GitWorkspacesEdge = {
  __typename?: 'GitWorkspacesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GitWorkspace;
};

export type KeyNotFoundError = Error & {
  __typename?: 'KeyNotFoundError';
  message: Scalars['String']['output'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteProject?: Maybe<DeleteProjectPayload>;
  patchGitWorkspace: PatchGitWorkspacePayload;
  patchProject: PatchProjectPayload;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationPatchGitWorkspaceArgs = {
  input?: InputMaybe<PatchGitWorkspaceInput>;
};


export type MutationPatchProjectArgs = {
  input: PatchProjectInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PatchGitWorkspaceError = KeyNotFoundError;

export type PatchGitWorkspaceInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PatchGitWorkspacePayload = {
  __typename?: 'PatchGitWorkspacePayload';
  errors?: Maybe<Array<PatchGitWorkspaceError>>;
  gitWorkspace?: Maybe<GitWorkspace>;
};

export type PatchProjectError = KeyNotFoundError;

export type PatchProjectInput = {
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PatchProjectPayload = {
  __typename?: 'PatchProjectPayload';
  errors?: Maybe<Array<PatchProjectError>>;
  project?: Maybe<Project>;
};

export type Project = {
  __typename?: 'Project';
  branch?: Maybe<Scalars['String']['output']>;
  commit?: Maybe<Commit>;
  createdBy?: Maybe<User>;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  details?: Maybe<Scalars['String']['output']>;
  gitWorkspace?: Maybe<GitWorkspace>;
  gitWorkspaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProjectsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Project;
};

export type Query = {
  __typename?: 'Query';
  availableTags: Array<Scalars['String']['output']>;
  commitsOfWorkspace?: Maybe<CommitsOfWorkspaceConnection>;
  gitWorkspace: GitWorkspace;
  gitWorkspaces?: Maybe<GitWorkspacesConnection>;
  globalProjectPermissions: Array<Scalars['String']['output']>;
  project: Project;
  projectPermissions: Array<Scalars['String']['output']>;
  projects?: Maybe<ProjectsConnection>;
};


export type QueryAvailableTagsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryCommitsOfWorkspaceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: GetProjectsOfWorkspaceInput;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGitWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGitWorkspacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectPermissionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySettingsInput = {
  descending: Scalars['Boolean']['input'];
  filters?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  pageSize: Scalars['Int']['input'];
  pageToken: Scalars['String']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GitWorkspaceDetailsPageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GitWorkspaceDetailsPageQuery = { __typename?: 'Query', gitWorkspace: { __typename?: 'GitWorkspace', id: string, name: string, description: string, createdOn: any, modifiedOn: any, gitConfiguration: { __typename?: 'GitConfiguration', checkoutFolders?: Array<string | null> | null, pathToProjectYaml?: string | null, repositoryUrl?: string | null, username?: string | null }, createdBy?: { __typename?: 'User', id: string, name: string } | null, modifiedBy?: { __typename?: 'User', id: string, name: string } | null } };

export type GitWorkspaceCommitsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  querySettings: QuerySettingsInput;
}>;


export type GitWorkspaceCommitsQuery = { __typename?: 'Query', commitsOfWorkspace?: { __typename?: 'CommitsOfWorkspaceConnection', totalCount: number, edges?: Array<{ __typename?: 'CommitsOfWorkspaceEdge', node: { __typename?: 'Project', id: string, name: string, description: string, branch?: string | null, gitWorkspaceId?: string | null, createdOn: any, modifiedOn: any, commit?: { __typename?: 'Commit', commitId?: string | null, message?: string | null, date: any, authorName?: string | null } | null, createdBy?: { __typename?: 'User', id: string, name: string } | null, modifiedBy?: { __typename?: 'User', id: string, name: string } | null } }> | null } | null };

export type PatchProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  details: Scalars['String']['input'];
}>;


export type PatchProjectMutation = { __typename?: 'Mutation', patchProject: { __typename?: 'PatchProjectPayload', project?: { __typename?: 'Project', id: string, name: string, description: string, details?: string | null } | null } };

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', name: string, description: string, details?: string | null, branch?: string | null, createdOn: any, modifiedOn: any, commit?: { __typename?: 'Commit', commitId?: string | null, message?: string | null, date: any, authorName?: string | null, authorEmail?: string | null } | null, createdBy?: { __typename?: 'User', id: string, name: string } | null, modifiedBy?: { __typename?: 'User', id: string, name: string } | null } };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID']['input'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject?: { __typename?: 'DeleteProjectPayload', success: boolean, message?: string | null } | null };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', globalProjectPermissions: Array<string>, projects?: { __typename?: 'ProjectsConnection', edges?: Array<{ __typename?: 'ProjectsEdge', node: { __typename?: 'Project', id: string, name: string, description: string } }> | null } | null, gitWorkspaces?: { __typename?: 'GitWorkspacesConnection', edges?: Array<{ __typename?: 'GitWorkspacesEdge', node: { __typename?: 'GitWorkspace', id: string, name: string, description: string, modifiedOn: any, modifiedBy?: { __typename?: 'User', id: string, name: string } | null } }> | null } | null };


export const GitWorkspaceDetailsPageDocument = gql`
    query gitWorkspaceDetailsPage($id: ID!) {
  gitWorkspace(id: $id) {
    id
    name
    description
    gitConfiguration {
      checkoutFolders
      pathToProjectYaml
      repositoryUrl
      username
    }
    createdBy {
      id
      name
    }
    createdOn
    modifiedBy {
      id
      name
    }
    modifiedOn
  }
}
    `;

/**
 * __useGitWorkspaceDetailsPageQuery__
 *
 * To run a query within a React component, call `useGitWorkspaceDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGitWorkspaceDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGitWorkspaceDetailsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGitWorkspaceDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<GitWorkspaceDetailsPageQuery, GitWorkspaceDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GitWorkspaceDetailsPageQuery, GitWorkspaceDetailsPageQueryVariables>(GitWorkspaceDetailsPageDocument, options);
      }
export function useGitWorkspaceDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GitWorkspaceDetailsPageQuery, GitWorkspaceDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GitWorkspaceDetailsPageQuery, GitWorkspaceDetailsPageQueryVariables>(GitWorkspaceDetailsPageDocument, options);
        }
export type GitWorkspaceDetailsPageQueryHookResult = ReturnType<typeof useGitWorkspaceDetailsPageQuery>;
export type GitWorkspaceDetailsPageLazyQueryHookResult = ReturnType<typeof useGitWorkspaceDetailsPageLazyQuery>;
export type GitWorkspaceDetailsPageQueryResult = Apollo.QueryResult<GitWorkspaceDetailsPageQuery, GitWorkspaceDetailsPageQueryVariables>;
export const GitWorkspaceCommitsDocument = gql`
    query gitWorkspaceCommits($id: ID!, $querySettings: QuerySettingsInput!) {
  commitsOfWorkspace(input: {gitWorkspaceId: $id, querySettings: $querySettings}) {
    edges {
      node {
        id
        name
        description
        branch
        gitWorkspaceId
        commit {
          commitId
          message
          date
          authorName
        }
        createdBy {
          id
          name
        }
        createdOn
        modifiedBy {
          id
          name
        }
        modifiedOn
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGitWorkspaceCommitsQuery__
 *
 * To run a query within a React component, call `useGitWorkspaceCommitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGitWorkspaceCommitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGitWorkspaceCommitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      querySettings: // value for 'querySettings'
 *   },
 * });
 */
export function useGitWorkspaceCommitsQuery(baseOptions: Apollo.QueryHookOptions<GitWorkspaceCommitsQuery, GitWorkspaceCommitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GitWorkspaceCommitsQuery, GitWorkspaceCommitsQueryVariables>(GitWorkspaceCommitsDocument, options);
      }
export function useGitWorkspaceCommitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GitWorkspaceCommitsQuery, GitWorkspaceCommitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GitWorkspaceCommitsQuery, GitWorkspaceCommitsQueryVariables>(GitWorkspaceCommitsDocument, options);
        }
export type GitWorkspaceCommitsQueryHookResult = ReturnType<typeof useGitWorkspaceCommitsQuery>;
export type GitWorkspaceCommitsLazyQueryHookResult = ReturnType<typeof useGitWorkspaceCommitsLazyQuery>;
export type GitWorkspaceCommitsQueryResult = Apollo.QueryResult<GitWorkspaceCommitsQuery, GitWorkspaceCommitsQueryVariables>;
export const PatchProjectDocument = gql`
    mutation PatchProject($id: ID!, $name: String!, $description: String!, $details: String!) {
  patchProject(
    input: {id: $id, name: $name, description: $description, details: $details}
  ) {
    project {
      id
      name
      description
      details
    }
  }
}
    `;
export type PatchProjectMutationFn = Apollo.MutationFunction<PatchProjectMutation, PatchProjectMutationVariables>;

/**
 * __usePatchProjectMutation__
 *
 * To run a mutation, you first call `usePatchProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchProjectMutation, { data, loading, error }] = usePatchProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      details: // value for 'details'
 *   },
 * });
 */
export function usePatchProjectMutation(baseOptions?: Apollo.MutationHookOptions<PatchProjectMutation, PatchProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchProjectMutation, PatchProjectMutationVariables>(PatchProjectDocument, options);
      }
export type PatchProjectMutationHookResult = ReturnType<typeof usePatchProjectMutation>;
export type PatchProjectMutationResult = Apollo.MutationResult<PatchProjectMutation>;
export type PatchProjectMutationOptions = Apollo.BaseMutationOptions<PatchProjectMutation, PatchProjectMutationVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(id: $id) {
    name
    description
    details
    branch
    commit {
      commitId
      message
      date
      authorName
      authorEmail
    }
    createdOn
    createdBy {
      id
      name
    }
    modifiedOn
    modifiedBy {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($projectId: ID!) {
  deleteProject(input: {id: $projectId}) {
    success
    message
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const ProjectsDocument = gql`
    query Projects {
  projects {
    edges {
      node {
        id
        name
        description
      }
    }
  }
  gitWorkspaces {
    edges {
      node {
        id
        name
        description
        modifiedBy {
          id
          name
        }
        modifiedOn
      }
    }
  }
  globalProjectPermissions
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;