import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client'
import { useContext } from 'react'

/**
 * Converts a UTC DateTime String or its integer representation to a more readable localized String
 * @param input unix timestamp in seconds
 */
export function convertUTCToLocal(input: number | string): string {
  if (input) {
    const date =
      typeof input === 'number' ? new Date(input * 1000) : new Date(input)
    return formatDate(date)
  } else {
    return input.toLocaleString()
  }
}

/**
 * Converts a unix time to a local DateTime object.
 * @param input unix timestamp in seconds
 */
export function convertUTCToLocalDateTime(input: number): Date {
  return new Date(input * 1000)
}

export const convertDateStringToLocalTime = (dateString: string): string => {
  const date = new Date(dateString)
  if (date) {
    return formatDate(date)
  } else {
    return ''
  }
}

const formatDate = (date: Date): string => {
  return Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date)
}

/** Predicate to filter null and undefined values from arrays with proper typing support. */
export const isNotNullOrUndefined = <T>(
  item: T | null | undefined
): item is T => item !== null && item !== undefined

export const createProviderHook = <TContextData>(
  context: React.Context<TContextData | undefined>,
  providerName: string,
  hookName: string
) => {
  return (): TContextData => {
    const contextData = useContext(context)

    if (contextData === undefined) {
      // Hook was called outside of its provider
      // => Throw an error to notify the developer
      throw new Error(
        `The ${hookName}() hook must only be called within a ${providerName}.`
      )
    }

    return contextData
  }
}

// Helper type for apollo wrapper functions
export type ApolloQuery<TQuery, TVariables extends OperationVariables> = (
  baseOptions: QueryHookOptions<TQuery, TVariables>
) => QueryResult<TQuery, TVariables>
