import { Operation, OperationsService } from '@simphera/shared/rest-clients'

export const getHasUnreadNotifications = async (
  lastTimeNotificationsWereRead: number
): Promise<boolean> => {
  const date = new Date(lastTimeNotificationsWereRead).toISOString()
  const res = await OperationsService.getOperations({
    pageSize: 1,
    filter: `createdOn ge datetimeoffset'${date}' or finishedOn ge datetimeoffset'${date}'`,
  })

  return Boolean(res.items && res.items.length > 0)
}

export const getNotifications = async (
  page: number,
  pageSize: number
): Promise<Array<Operation>> => {
  const res = await OperationsService.getOperations({
    pageSize,
    pageToken: String(page),
  })

  return res.items || []
}

export const getNotification = async (id: string): Promise<Operation> => {
  return await OperationsService.getOperationsByOperationId({
    operationId: id,
  })
}
