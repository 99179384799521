import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  PATHS,
  ScenarioIcon,
  SidebarItemInterpolate,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'

const ScenarioListPage = React.lazy(
  () => import('./components/ScenarioListPage/ScenarioListPage')
)
const ScenarioDetailsPage = React.lazy(
  () => import('./components/ScenarioDetailsPage/ScenarioDetailsPage')
)
const ScenarioImportPage = React.lazy(
  () =>
    import(
      './components/ScenarioImportPage/ModelDeskScenario/ModelDeskScenarioImportPage'
    )
)
const OpenScenarioImportPage = React.lazy(
  () =>
    import(
      './components/ScenarioImportPage/OpenScenario/OpenScenarioImportPage'
    )
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SCENARIOS_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="Scenarios"
      icon={<ScenarioIcon />}
      to={PATHS.SCENARIOS.LIST(':projectId')}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) => PATHS.SCENARIOS.LIST(projectId),
    icon: <ScenarioIcon />,
    label: 'Scenarios',
  },
  routes: [
    {
      path: PATHS.SCENARIOS.LIST(':projectId'),
      element: wrapComponentInProviders(ScenarioListPage),
    },
    {
      path: PATHS.SCENARIOS.IMPORT_MODEL_DESK(':projectId'),
      element: wrapComponentInProviders(ScenarioImportPage),
    },
    {
      path: PATHS.SCENARIOS.IMPORT_OPEN_SCENARIO(':projectId'),
      element: wrapComponentInProviders(OpenScenarioImportPage),
    },
    {
      path: PATHS.SCENARIOS.DETAIL(':projectId', ':scenarioId'),
      element: wrapComponentInProviders(ScenarioDetailsPage),
    },
  ],
}
