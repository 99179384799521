import React from 'react'

import {
  KpiFloatComparisonNode,
  KpiGraphNodeComponentProps,
} from '../../kpiGraphTypes'
import KpiNodeFrame from './KpiNodeFrame'

export const FloatComparisonNode: React.FC<
  KpiGraphNodeComponentProps<KpiFloatComparisonNode>
> = ({ model, engine, node }) => {
  return (
    <KpiNodeFrame model={model} engine={engine}>
      <span>{node.operator}</span>
    </KpiNodeFrame>
  )
}
